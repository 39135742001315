<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="中间商名称" prop="middleMan">
          <el-input placeholder="中间商名称" v-model="params.middleMan" />
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input placeholder="联系人" v-model="params.linkMan" />
        </el-form-item>
        <el-form-item label="联系方式" prop="linkPhone">
          <el-input placeholder="联系方式" v-model="params.linkPhone" />
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button
        v-auth="'supplier:listAssociativeMiddleman'"
        type="primary"
        @click="relateSupplier"
        >关联中间商</el-button
      >
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" v-loading="loading" height="100%" border>
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="中间商名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkMan"
          label="联系人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="linkPhone"
          label="联系方式"
        ></el-table-column>
        <el-table-column
          prop="supplyAddress"
          label="供货范围"
        ></el-table-column>
        <el-table-column width="200" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'supply-node:list'"
              @click="distributeHandler(row)"
              size="mini"
              type="text"
            >
              管理供货范围</el-button
            >

            <el-button
              v-auth="'supplier:relieveRelation'"
              v-if="row.payStatus == 2 && row.status == 1"
              @click="unrelate(row.purchaseNo, row.id)"
              size="mini"
              type="text"
            >
              解除关联</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <RelateSupplierModal
      v-if="relateSupplierVisible"
      @getData="getData(true)"
      :visible.sync="relateSupplierVisible"
    />

    <DistributeModal
      @getData="getData(true)"
      v-if="distributeVisible"
      :visible.sync="distributeVisible"
      :middlemanId="middlemanId"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import RelateSupplierModal from './components/RelateSupplierModal'
import DistributeModal from '../../userManagement/supplier/components/DistributeModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      supplierId: null,
      middlemanId: null,
      loading: false,
      relateSupplierVisible: false,
      distributeVisible: false,
      tableData: [],
      totalCount: 0,
      params: {
        limit: 20,
        page: 1
      }
    }
  },
  components: {
    RelateSupplierModal,
    DistributeModal
  },
  created() {
    this.getData()
  },
  methods: {
    relateSupplier() {
      this.relateSupplierVisible = true
    },
    distributeHandler(row) {
      this.middlemanId = row.id
      this.distributeVisible = true
    },
    unrelate(id) {
      this.$confirm(`是否解除该中间商与供应商的关系`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await this.$api.supplier.unrelate(id)
          this.$message.success('解除成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
        }
      })
    },
    async getData(query) {
      this.loading = true

      if (query) {
        this.params.limit = 20
        this.params.page = 1
      }

      try {
        const r = await this.$api.supplier.getRelateSupplierList(this.params)
        this.tableData = ((r.page && r.page.list) || []).map(item => {
          item.supplyAddress = (item.nodeVoList || [])
            .map(_ => _.nodeName)
            .join('；')

          return item
        })

        this.totalCount = (r.page && r.page.totalCount) || 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
