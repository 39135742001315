<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="关联中间商"
    :visible.sync="show"
    width="600px"
  >
    <el-table
      v-loading="loading"
      :data="tableData"
      @selection-change="selectHandler"
      max-height="300"
      style="width: 100%"
      top="10vh"
    >
      <el-table-column
        show-overflow-tooltip
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column show-overflow-tooltip prop="name" label="中间商名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="linkMan" label="联系人">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="linkPhone" label="联系电话">
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button
        :disabled="!selections.length"
        type="primary"
        @click="submitHandler"
        :loading="btnLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      btnLoading: false,
      selections: [],
      tableData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    selectHandler(val) {
      this.selections = val.map(item => item.id)
    },
    async getData() {
      try {
        this.loading = true

        const r = await this.$api.supplier.getSupplier()

        this.tableData = (r.middlemanVoList || []).map(item => {
          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async submitHandler() {
      this.btnLoading = true
      try {
        await this.$api.supplier.addRelationMiddleman({
          middlemanIdList: this.selections
        })

        this.$message.success('添加成功')
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
